import { useEffect } from 'react';
import ReactGA from 'react-ga4';

ReactGA.initialize("G-SX54SMNLNP");

const Analytics = ({ location, title }) => {

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location, title: title, });
  }, [location, title]);

  return null;
};

export default Analytics;