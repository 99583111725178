import React, { useState, useEffect } from 'react';
import './ArticlesDemo.css'; // Import the CSS file

const NYDemo = () => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/singleNYC`);      
        const data = await response.json();
        if (data.articles.success) {
          setArticles(data.articles.results);
        } else {
          console.error('Failed to fetch articles');
        }
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="article-list-container"> 
      <h3 className='cardTitle black'>New York News Demo</h3>
      <div className="cardContainer">
        {articles.slice(0, 1).map(article => (
          <div key={article.articleID} className="mainArticle">
            {article.picture && <img className='articleIMG' src={article.picture} alt={article.title} />}
            <h4 className='black noMar'>{article.title}</h4>
            <p className='smallText'><strong>Published on: {new Date(article.creation).toLocaleDateString()}</strong></p>
            <p className='smallText'>{article.summary}</p>
          </div>
        ))}
        <div className="secondary-articles">
          {articles.slice(1).map(article => (
            <div key={article.articleID} className="secondary-article">
              <h4 className='black noMar'><strong>{article.title}</strong></h4>
              <p className='smallText'><strong>Published on: {new Date(article.creation).toLocaleDateString()}</strong></p>
              <p className='smallText'>{article.summary}</p>
            </div>
          ))}
          <a href="/NYC" target="_blank" rel="noopener noreferrer" className='blankLink'><div className='trigger'>See more of the NY demo</div></a>
        </div>
      </div>
    </div>
  );
};

export default NYDemo;