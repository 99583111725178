import React from 'react';

function CloseIcon() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.87186 7.5L14.7156 1.65631C14.8061 1.56621 14.8778 1.45906 14.9266 1.34105C14.9754 1.22304 15.0003 1.09652 14.9999 0.968814C15.0015 0.841403 14.9771 0.715004 14.9282 0.597337C14.8793 0.47967 14.807 0.373206 14.7156 0.284446C14.6268 0.193025 14.5203 0.120658 14.4027 0.0717642C14.285 0.0228707 14.1586 -0.00152284 14.0312 7.35888e-05C13.9035 -0.000342798 13.777 0.0245768 13.659 0.0733896C13.5409 0.122202 13.4338 0.193939 13.3437 0.284446L7.5 6.12814L1.65631 0.284446C1.56621 0.193939 1.45906 0.122202 1.34105 0.0733896C1.22304 0.0245768 1.09652 -0.000342798 0.968814 7.35888e-05C0.841403 -0.00152284 0.715004 0.0228707 0.597337 0.0717642C0.47967 0.120658 0.373206 0.193025 0.284446 0.284446C0.193025 0.373206 0.120658 0.47967 0.0717642 0.597337C0.0228707 0.715004 -0.00152284 0.841403 7.35888e-05 0.968814C-0.000342798 1.09652 0.0245768 1.22304 0.0733896 1.34105C0.122202 1.45906 0.193939 1.56621 0.284446 1.65631L6.12814 7.5L0.284446 13.3437C0.193939 13.4338 0.122202 13.5409 0.0733896 13.659C0.0245768 13.777 -0.000342798 13.9035 7.35888e-05 14.0312C-0.00152284 14.1586 0.0228707 14.285 0.0717642 14.4027C0.120658 14.5203 0.193025 14.6268 0.284446 14.7156C0.373206 14.807 0.47967 14.8793 0.597337 14.9282C0.715004 14.9771 0.841403 15.0015 0.968814 14.9999C1.09652 15.0003 1.22304 14.9754 1.34105 14.9266C1.45906 14.8778 1.56621 14.8061 1.65631 14.7156L7.5 8.87186L13.3437 14.7156C13.4338 14.8061 13.5409 14.8778 13.659 14.9266C13.777 14.9754 13.9035 15.0003 14.0312 14.9999C14.2879 14.9991 14.5338 14.8968 14.7153 14.7153C14.8968 14.5338 14.9991 14.2879 14.9999 14.0312C15.0003 13.9035 14.9754 13.777 14.9266 13.659C14.8778 13.5409 14.8061 13.4338 14.7156 13.3437L8.87186 7.5Z" fill="white"/>
    </svg>
  );
}

export default CloseIcon;