import React from 'react';

const SmallLogo = () => {
  const handleLogoClick = () => {
    window.location.href = '/'; // navigate to the homepage
    window.location.reload();   // force a page refresh
  };

  return (
    <a href="/" onClick={handleLogoClick}>      
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.55556 0H24.4444C27.5127 0 30 2.48731 30 5.55556V24.4444C30 27.5127 27.5127 30 24.4444 30H5.55556C2.48731 30 0 27.5127 0 24.4444V5.55556C0 2.48731 2.48731 0 5.55556 0ZM5.55556 2.77778C4.02143 2.77778 2.77778 4.02143 2.77778 5.55556V24.4444C2.77778 25.9786 4.02143 27.2222 5.55556 27.2222H24.4444C25.9786 27.2222 27.2222 25.9786 27.2222 24.4444V5.55556C27.2222 4.02143 25.9786 2.77778 24.4444 2.77778H5.55556Z" fill="#0057FF"/>
            <path d="M11.4526 13.2123L11.285 12.3638C10.7543 13.0482 10.07 13.3903 9.23203 13.3903C8.10779 13.3903 7.207 13.0132 6.52966 12.2591C5.88026 11.5399 5.55556 10.6216 5.55556 9.50436C5.55556 8.35917 5.88026 7.42347 6.52966 6.69725C7.20002 5.93612 8.1008 5.55556 9.23203 5.55556C10.0351 5.55556 10.7298 5.7825 11.3164 6.23638C11.9449 6.72518 12.301 7.37808 12.3848 8.19507H10.8136C10.7229 7.79007 10.5343 7.47933 10.248 7.26286C9.96872 7.04639 9.63005 6.93816 9.23203 6.93816C8.54072 6.93816 8.01701 7.20351 7.66089 7.73421C7.35364 8.18809 7.20002 8.77814 7.20002 9.50436C7.20002 10.2026 7.35364 10.7752 7.66089 11.2221C8.02399 11.7459 8.54771 12.0077 9.23203 12.0077C10.3214 12.0077 10.9149 11.47 11.0127 10.3947H9.35772V9.16918H12.5V13.2123H11.4526Z" fill="#0057FF"/>
            <path d="M5.55556 21.6667H24.4444V24.4444H5.55556V21.6667Z" fill="#0057FF"/>
            <path d="M5.55556 16.1111H24.4444V18.8889H5.55556V16.1111Z" fill="#0057FF"/>
        </svg>
    </a>
  );
}; 

export default SmallLogo;