import React, { useState, useEffect } from 'react';
import './ArticlesList.css';
import SmallLogo from './icons/SmallLogo';
import FormWithPopup from './ProjectForm';
import Analytics from './Analytics';

const PAGE_SIZE = 10;
const currentUrl = window.location.href;
const pathname = window.location.pathname;
const element = pathname.substring(1); // Remove the leading '/'

const ArticleList = () => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Extracting ${element} from the URL
        
        const response = await fetch(`/${element}articles?page=${currentPage}&pageSize=${PAGE_SIZE}`);

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        if (currentPage === 1) {
          setItems(data.articles.results);
        } else {
          setItems(prevItems => [...prevItems, ...data.articles.results]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      setLoading(false);
    };

    fetchData();
  }, [currentPage]);

  useEffect(() => {
    function handleScroll() {
      const bodyHeight = document.body.offsetHeight;
      const windowHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const bottom = windowHeight + scrollY >= bodyHeight - 100;
      if (bottom && !loading) {
        setCurrentPage(currentPage => currentPage + 1);
      }
    }

    let timeoutId;
    function debouncedHandleScroll() {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleScroll, 200);
    }

    if (!loading) {
      window.addEventListener('scroll', debouncedHandleScroll);
    }

    return () => {
      window.removeEventListener('scroll', debouncedHandleScroll);
      clearTimeout(timeoutId);
    };
  }, [currentPage, loading]);

  return (
    <div className='mainContainer smallContainer'>
      <Analytics location={currentUrl} title={element}/>
      <div className='articleNav'>
        <SmallLogo />
        <div className='flexBet rowG noFlex'>
        {element === 'NYC' ? (
              <>
                <a className='blankLink' href="/NYC" target="_blank" rel="noopener noreferrer"><h4 className={`noMar`} id='NYC'>{window.innerWidth < 768 ? 'NY Demo' : 'New York Demo'}</h4></a>
                <a className='blankLink' href="/Stocks" target="_blank" rel="noopener noreferrer"><h4 className={`noMar ${element === 'Stocks' ? '' : 'greyText hoverLink'}`} id='Stocks'>{window.innerWidth < 768 ? 'Stocks Demo' : 'Stock Market Demo'}</h4></a>
                <a className='blankLink' href="/AI" target="_blank" rel="noopener noreferrer"><h4 className={`noMar ${element === 'AI' ? '' : 'greyText hoverLink'}`} id='AI'>AI Demo</h4></a>
              </>
          ) : element === 'Stocks' ? (
              <>
                <a className='blankLink' href="/Stocks" target="_blank" rel="noopener noreferrer"><h4 className={`noMar`} id='Stocks'>{window.innerWidth < 768 ? 'Stocks Demo' : 'Stock Market Demo'}</h4></a>
                <a className='blankLink' href="/NYC" target="_blank" rel="noopener noreferrer"><h4 className={`noMar ${element === 'NYC' ? '' : 'greyText hoverLink'}`} id='NYC'>{window.innerWidth < 768 ? 'NY Demo' : 'New York Demo'}</h4></a>
                <a className='blankLink' href="/AI" target="_blank" rel="noopener noreferrer"><h4 className={`noMar ${element === 'AI' ? '' : 'greyText hoverLink'}`} id='AI'>AI Demo</h4></a>
              </>
          ) : (
              <>
                <a className='blankLink' href="/AI" target="_blank" rel="noopener noreferrer"><h4 className={`noMar`} id='AI'>AI Demo</h4></a>
                <a className='blankLink' href="/NYC" target="_blank" rel="noopener noreferrer"><h4 className={`noMar ${element === 'NYC' ? '' : 'greyText hoverLink'}`} id='NYC'>{window.innerWidth < 768 ? 'NY Demo' : 'New York Demo'}</h4></a>
                <a className='blankLink' href="/Stocks" target="_blank" rel="noopener noreferrer"><h4 className={`noMar ${element === 'Stocks' ? '' : 'greyText hoverLink'}`} id='Stocks'>{window.innerWidth < 768 ? 'Stocks Demo' : 'Stock Market Demo'}</h4></a>
              </>
          )}
        </div>
      </div>
      <div>
        {items.map(article => (
          <div className='articleElement' key={article.articleID}>
            <h2>{article.title}</h2>
            {article.picture && <img className='articleIMG' src={article.picture} alt={article.title} />}
            <p className='articleSmall'><strong>Published on: {new Date(article.creation).toLocaleDateString()}</strong></p>
            <p>{article.summary}</p>
            <div class='mainButton'><a className='blankLink' href={article.link} target="_blank" rel="noopener noreferrer">Read full article</a></div>
            <p className='articleSmall'><strong>{article.media}</strong></p>
          </div>
        ))}
      </div>
      <div className='footerFixed'>
          <FormWithPopup />
      </div>
    </div>
  );
};

export default ArticleList;
