import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainComponent from './components/MainComponent';
import ArticleList from './components/ArticlesList';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainComponent />} />
        <Route path="/NYC" element={<ArticleList />} />
        <Route path="/Stocks" element={<ArticleList />} />
        <Route path="/AI" element={<ArticleList />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;