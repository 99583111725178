import React, { useState, useEffect } from 'react';
import './ProjectForm.css';
import CloseIcon from './icons/CloseIcon';
import SmallLogo from './icons/SmallLogo';

function FormWithPopup() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [popupInput1, setPopupInput1] = useState('');
  const [popupInput2, setPopupInput2] = useState('');
  const [input1HasContent, setInput1HasContent] = useState(false);
  const [input2HasContent, setInput2HasContent] = useState(false);

  useEffect(() => {
    setInput1HasContent(popupInput1.trim() !== '');
  }, [popupInput1]);

  useEffect(() => {
    setInput2HasContent(popupInput2.trim() !== '');
  }, [popupInput2]);

  const handleNextSlide = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setCurrentSlide(1); 
  };

  const handleSend = async () => {
    if (currentSlide === 2) {
      setIsPopupOpen(false);
      document.getElementById('mainScreen').getElementsByClassName('formSl')[0].style.display = 'none';
      document.querySelector('#mainScreen h4').textContent = "Thank you for your interest in Granular, a member of our team will get back to you shortly.";
      try {
        const data = {
          subject: `New Project from ${popupInput1}`,
          body: `Topics: ${inputValue}\nEmail: ${popupInput1}\nName: ${popupInput2}`,
        };
    
        const response = await fetch('/newForm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });
    
        if (response.ok) {
          console.log("Thank you for your interest in Granular")
        } else {
          console.error('Request failed with status:', response.status);
        }
      } catch (error) {
        console.error('Network error:', error);
      }
    } else {
      setIsPopupOpen(true);
    }
  };

  return (
    <div className='fullS' id="mainScreen">
      <h4 className='noMar'>Request a personalized demo</h4>
        <div className='formSl'>
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Add the topics you want to explore. For ex: Stock market, NYSE, Wall Street, ..."
            onKeyDown={(e) => {
              if (e.key === 'Enter' && inputValue.trim() !== '') {
                handleSend();
              }
            }}  
          />
          <button 
            className={inputValue.trim() !== '' ? 'formButton formButtonActive' : 'formButton'} 
            onClick={handleSend} 
            disabled={inputValue.trim() === ''}
          >
            Send
          </button>
        </div>
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popup">
            {currentSlide === 1 && (
              <>
                <div className='fullS'>
                  <h4 className='noMar'>Where can we contact you? <span className='greyText'>*</span></h4>
                  <div className='formSl'>
                    <input
                      type="email"
                      value={popupInput1}
                      onChange={(e) => setPopupInput1(e.target.value)}
                      placeholder="warren@berkshire.com"
                      autoFocus 
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && input1HasContent) {
                          handleNextSlide();
                        }
                      }}               
                    />
                    <button className={popupInput1.trim() !== '' ? 'formButton formButtonActive' : 'formButton'} onClick={handleNextSlide} disabled={!input1HasContent}>Next</button>
                  </div>
                </div>
              </>
            )}
            {currentSlide === 2 && (
              <>
                <div className='fullS'>
                  <h4 className='noMar'>How can we call you? <span className='greyText'>*</span></h4>
                  <div className='formSl'>
                    <input
                      type="text"
                      value={popupInput2}
                      onChange={(e) => setPopupInput2(e.target.value)}
                      placeholder="Warren Buffet"
                      autoFocus
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && input2HasContent) {
                          handleSend();
                        }
                      }}              
                    />
                    <button className={popupInput2.trim() !== '' ? 'formButton formButtonActive' : 'formButton'} disabled={!input2HasContent} onClick={handleSend}>Send</button>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="closeButton" onClick={handleClosePopup}>
            <CloseIcon />
          </div>
          <div className="popupLogo" onClick={handleClosePopup}>
            <SmallLogo />
          </div>
        </div>
      )}
    </div>
  );
}

export default FormWithPopup;