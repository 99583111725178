import React from 'react';
import './MainComponent.css';
import FormWithPopup from './ProjectForm';
import MainLogo from './icons/MainLogo';
import NYDemo from './NYDemo';
import AIDemo from './AIDemo';
import StocksDemo from './StocksDemo';
import SmallLogo from './icons/SmallLogo';
import FooterForm from './FooterForm';
import Analytics from './Analytics';

const currentUrl = window.location.href;

const MainComponent = () => {
  return (
      <div className='mainContainer'>
        <Analytics location={currentUrl} title="Home"/>
        <div className='flexBet heroGap paddingElement'>
            <div className='heroContent'>
                <MainLogo />
                <h1>Custom newsfeeds with the informations you need</h1>
                <p>We create custom news aggregators fine-tuned to the topics and sources you need for your business.</p>
                <FormWithPopup />
                <p className='greyText mediumP'><strong>Used by +10000 users monthly.</strong></p>
            </div>
            <div>
                <img className='heroImg' src="/hero-picture.png" alt="main"/>
            </div>
        </div>
        <div className='flexBet paddingElement'>
            <h2><span className='greyText'>Trusted by</span><br/>industry leaders</h2>
            <img className='industryLogo' src="/BlackstoneLogo.svg" alt="Blackstone Logo" />
            <img className='industryLogo' src="/BiogenResearch.svg" alt="Biogen" />
            <img className='industryLogo' src="/CarlyleInvestment.svg" alt="Biogen" />
            <img className='industryLogo' src="/HalliburtonLogo.svg" alt="Biogen" />
            <img className='industryLogo' src="/BainEngineering.svg" alt="Biogen" />
            <img className='industryLogo' src="/C17Logo.svg" alt="Biogen" />
        </div>
        <div className='elementRow paddingElement'>
            <div className='elementHeader'>
                <h2><span className='greyText'>With Granular you have </span><br/>the news you need, when you need it, in a single place</h2>
            </div>
            <div className='elementRows'>
                <div className='rowElement'>
                    <div className='flexBet rowGap'>
                        <div className='flexContent'>
                            <h3>Personalized insights</h3>
                            <p>Customize your news feed to align with your professional interests, industry trends, and strategic objectives.</p>
                        </div>
                        <div>
                            <img className='heroImg' src="/custom-insights.svg" alt="Personalized insights"/>
                        </div>
                    </div>
                </div>
                <div className='rowElement'>
                    <div className='flexBet rowGap reverse'>
                        <div>
                            <img className='heroImg' src="/AdvancedFilters.svg" alt="Personalized insights"/>
                        </div>
                        <div className='flexContent'>
                            <h3>Advanced filtering options</h3>
                            <p>Refine your news feed with powerful filters, including keywords, topics, sources, and more.</p>
                        </div>
                    </div>
                </div>
                <div className='rowElement'>
                    <div className='flexBet rowGap'>
                        <div className='flexContent'>
                            <h3>Multi-Source Aggregation</h3>
                            <p>Access a wide array of +10000 authoritative news sources and industry publications, consolidating pertinent information in one centralized platform.</p>
                        </div>
                        <div>
                            <img className='heroImg' src="/Multisource-aggregation.svg" alt="Personalized insights"/>
                        </div>
                    </div>
                </div>
                <div className='rowElement'>
                    <div className='flexBet rowGap reverse'>
                        <div>
                            <img className='heroImg' src="/Realtime-updates.svg" alt="Real-time updates"/>
                        </div>
                        <div className='flexContent'>
                            <h3>Real-time updates</h3>
                            <p>Stay informed with up-to-the-minute news updates and breaking stories.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='elementRow paddingElement'>
            <div className='elementHeader'>
                <h2><span className='greyText'>How it works live? </span><br/>Here are some example of custom Granular-news</h2>
            </div>
            <div className='elementRows'>
                <div className='rowElement'>
                    <div className='flexBet rowGap'>
                        <NYDemo />
                    </div>
                </div>
                <div className='rowElement'>
                    <div className='flexBet rowGap'>
                        <StocksDemo />
                    </div>
                </div>
                <div className='rowElement'>
                    <div className='flexBet rowGap'>
                        <AIDemo />
                    </div>
                </div>
            </div>
        </div>
        <div className='elementRow'>
            <div className='elementHeader'>
                <h2><span className='greyText'>They tried Granular </span><br/>and they love using it!</h2>
            </div>
            <div className='testimonialElement'>
                <p><strong>“</strong> Granular's tailored aggregation capabilities have optimized my data acquisition process, providing me with relevant articles, studies, and news updates all in one place. In a fast-paced field like health scientific research, This platform delivers precise insights, allowing me to stay ahead of the curve with real-time updates on industry advancements. <strong>”</strong></p>
                <div className='fitContent'>
                    <img className='testimonialImg' src="/Haley-Davies-profile.jpg" alt="Haley Davies"/>
                    <h4>Hailey Davies • </h4>
                    <p> Neuroscience Research Scientist @BiogenResearch</p>
                </div>
            </div>
        </div>
        <div className='elementRow paddingElement'>
            <h2 className='margT'><span className='greyText'>Create your custom newsfeed </span><br/>and get the information you need, when you need it!</h2>
            <FooterForm />
        </div>
        <div className='mainFooter'>
            <SmallLogo />
            <div className='flexBet rowG'>
              <a className='blankLink' href="/NYC" target="_blank" rel="noopener noreferrer"><h4 className='noMar greyText hoverLink'>New York Demo</h4></a>
              <a className='blankLink' href="/Stocks" target="_blank" rel="noopener noreferrer"><h4 className='noMar greyText hoverLink'>Stock Market Demo</h4></a>
              <a className='blankLink' href="/AI" target="_blank" rel="noopener noreferrer"><h4 className='noMar greyText hoverLink'>AI Demo</h4></a>
            </div>        
            <p className='noMar mediumP'>©2024 Granular</p>
        </div>
    </div>
  );
}

export default MainComponent;